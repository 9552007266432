import { IFilterUiState } from '../interface/filter-ui-state';
import {
  FilterUiMessages,
  InitializeFilterMessage,
  SelectAllCompleteMessage,
  SelectAllInProgressMessage,
  UpdateSelectedFilterGroupMessage,
  UpdateSelectedProfessionMessage,
} from './filter-ui.messages';
export * from './filter-ui.selectors';

export const INITIAL_UI_STATE: IFilterUiState = {
  selectedFilterGroup: 'Profession',
  selectedProfessionId: null,
  selectAllInProgress: false,
};

export function reducer(state: IFilterUiState = INITIAL_UI_STATE, action: FilterUiMessages) {
  switch (action.type) {
    case InitializeFilterMessage.TYPE:
      return { ...INITIAL_UI_STATE, ...action.payload };
    case UpdateSelectedFilterGroupMessage.TYPE:
      state = {
        ...state,
        selectedFilterGroup: (action as UpdateSelectedFilterGroupMessage).payload
          .selectedFilterGroup,
        selectedProfessionId: null,
      };
      break;
    case UpdateSelectedProfessionMessage.TYPE:
      state = {
        ...state,
        selectedProfessionId: (action as UpdateSelectedProfessionMessage).payload
          .selectedProfessionId,
      };
      break;
    case SelectAllInProgressMessage.TYPE:
      state = {
        ...state,
        selectAllInProgress: true,
      };
      break;
    case SelectAllCompleteMessage.TYPE:
      state = {
        ...state,
        selectAllInProgress: false,
      };
      break;

    default:
      break;
  }

  return state;
}
