import { messageableFactory as actionableFactory } from './filter-ui.adapter';

export class InitializeFilterMessage extends actionableFactory.create<
  'Initialize Filter',
  Record<string, never>
>('Initialize Filter') {}

export class UpdateSelectedFilterGroupMessage extends actionableFactory.create<
  'Update Selected Filter Group Message',
  { selectedFilterGroup: string }
>('Update Selected Filter Group Message') {}

export class UpdateSelectedProfessionMessage extends actionableFactory.create<
  'Update Selected Profession Message',
  { selectedProfessionId: number }
>('Update Selected Profession Message') {}

export class SelectAllInProgressMessage extends actionableFactory.create<
  'Select All In Progress Message',
  Record<string, never>
>('Select All In Progress Message') {}

export class SelectAllCompleteMessage extends actionableFactory.create<
  'Select All Complete Message',
  Record<string, never>
>('Select All Complete Message') {}

export type FilterUiMessages =
  | InitializeFilterMessage
  | UpdateSelectedFilterGroupMessage
  | UpdateSelectedProfessionMessage
  | SelectAllInProgressMessage
  | SelectAllCompleteMessage;
