import { signalableFactory as actionableFactory } from './filter-ui.adapter';

export class InitializeFilterContainerSignal extends actionableFactory.create<
  'Initialize Filter Container',
  Record<string, never>
>('Initialize Filter Container') {}

export class UpdateSelectedFilterGroupSignal extends actionableFactory.create<
  'Update Selected Filter Group Signal',
  { selectedFilterGroup: string }
>('Update Selected Filter Group Signal') {}

export class UpdateSelectedProfessionSignal extends actionableFactory.create<
  'Update Selected Profession Signal',
  { selectedProfessionId: number }
>('Update Selected Profession Signal') {}
