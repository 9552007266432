import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { isNil } from 'lodash-es';
import { combineLatest, merge, of } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';

import {
  FilterNamespace,
  IFilterCategory,
  IFilterCategoryPayload,
  IFilterGroup,
  IFilterSelectAllPayload,
} from '@locumsnest/components/src/lib/interfaces/filter';

import { IProfessionEntity } from '../../interfaces/api/profession-entity';
import { PreferredPassportProfessionService } from '../../preferred-passport-profession/+state/preferred-passport-profession.service';
import { ProfessionService } from './../../profession/+state/profession.service';
import {
  selectSelectAllInProgress,
  selectSelectedFilterGroup,
  selectSelectedProfessionId,
} from './filter-ui';
import { InitializeFilterContainerSignal } from './filter-ui/filter-ui.signals';

@Injectable({
  providedIn: 'root',
})
export class PassportFilterService {
  constructor(
    private store: Store,
    private professionService: ProfessionService,
    private preferredPassportProfessionService: PreferredPassportProfessionService
  ) {}

  initializeFilters() {
    return this.store.dispatch(new InitializeFilterContainerSignal({}));
  }

  loadFilters() {
    return merge(this.professionService.load(), this.preferredPassportProfessionService.load());
  }

  getFilterGroups() {
    return this.getProfessionFilterGroups().pipe(map((profession) => [profession]));
  }

  getFilterCount() {
    return this.getProfessionFilterGroups().pipe(map((profession) => profession.count));
  }

  getFilterAlert() {
    return of('');
  }

  getSelectedProfessionId() {
    return this.store.pipe(select(selectSelectedProfessionId));
  }

  getSelectAllInProgress() {
    return this.store.pipe(select(selectSelectAllInProgress));
  }
  getSelectedFilterGroup() {
    return this.store.pipe(select(selectSelectedFilterGroup));
  }

  getSelectedProfession() {
    return this.getSelectedProfessionId().pipe(
      switchMap((id) => this.professionService.getOne(id))
    );
  }
  canSelectAll() {
    return combineLatest([this.getProfessionFilterGroups(), this.getSelectedFilterGroup()]).pipe(
      map(([professions, selectedFilterGroup]) => {
        if (selectedFilterGroup === 'Profession') {
          return professions.filterCategories.every((category) => category.selected);
        }

        return false;
      })
    );
  }

  getProfessionFilterGroups() {
    return combineLatest([
      this.professionService.getAllActive(),
      this.preferredPassportProfessionService.getAllTempProfessionIds(),
    ]).pipe(
      map(
        ([professions, preferredPassportProfessionIds]: [
          IProfessionEntity[],
          number[]
        ]): IFilterGroup => {
          const filterGroupsSections = professions.map((profession) => {
            const preferredProfession = preferredPassportProfessionIds.find(
              (x) => x === profession.id
            );

            const filterCategory: IFilterCategory = {
              id: profession.id,
              displayName: profession.title,
              selected: !isNil(preferredProfession),
              count: 0,
              filterOptions: [],
            };

            return filterCategory;
          });

          const filterGroup: IFilterGroup = {
            displayName: 'Professions',
            slug: 'Profession',
            count: preferredPassportProfessionIds.length,
            filterCategories: filterGroupsSections,
            hasPreferredProfession: preferredPassportProfessionIds.length > 0,
            namespace: FilterNamespace.passport,
          };

          return filterGroup;
        }
      )
    );
  }

  dispatchToggleSignal(payload: IFilterCategoryPayload) {
    return this.preferredPassportProfessionService.isFilterEnabled$.pipe(
      first(),
      filter((isEnabled) => isEnabled === true),
      switchMap(() => {
        this.preferredPassportProfessionService.dispatchToggleSignal(payload);
        return of();
      })
    );
  }
  dispatchToggleAllProfessionsSignal(payload: IFilterSelectAllPayload) {
    return this.preferredPassportProfessionService.isFilterEnabled$.pipe(
      first(),
      filter((isEnabled) => isEnabled === true),
      switchMap(() => {
        this.preferredPassportProfessionService.dispatchToggleAllSignal(payload);
        return of();
      })
    );
  }
}
