import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IFilterUiState } from '../interface/filter-ui-state';

const getSelectedProfessionId = (filterUiState: IFilterUiState) =>
  filterUiState.selectedProfessionId;

const getSelectedFilterGroup = (filterUiState: IFilterUiState) => filterUiState.selectedFilterGroup;
const getSelectAllInProgress = (filterUiState: IFilterUiState) => filterUiState.selectAllInProgress;

// #region selectSelectors

export const selectFilterUiState = createFeatureSelector<IFilterUiState>('passportFilterUi');

export const selectSelectedFilterGroup = createSelector(
  selectFilterUiState,
  getSelectedFilterGroup
);

export const selectSelectedProfessionId = createSelector(
  selectFilterUiState,
  getSelectedProfessionId
);

export const selectSelectAllInProgress = createSelector(
  selectFilterUiState,
  getSelectAllInProgress
);

// #endregion selectSelectors
